"use strict";

$( "form" ).each( function() {
    $( this ).validate({
    errorElement: 'span',
    errorPlacement: function (error, element) {
      error.appendTo(element.parent('div'))
    },
    ignore: ':hidden',
    rules: {
      name: {
        required: true,
        minlength: 2
      },
      surname: {
        required: true,
        minlength: 2
      },
      patronymic: {
        required: true,
        minlength: 2
      },
      login: {
        required: true,
      },
      phone2: {
        required: true,
        minlength: 18,
        maxlength: 18
      },
      phone3: {
        required: true,
        minlength: 18,
        maxlength: 18
      },
      pass: {
        required: true,
        //maxlength: 8
      }
    },
    messages: {
      name: {
        required: 'Пожалуйста введите имя',
        minlength: 'Ваше Имя слишком короткое'
      },
      surname: {
        required: 'Пожалуйста введите фамилию',
        minlength: 'Ваше фамилия слишком короткая'
      },
      patronymic: {
        required: 'Пожалуйста введите отчество',
        minlength: 'Ваше отчество слишком короткое'
      },
      login: {
        required: 'Пожалуйста введите логин',
      },
      phone2: {
        required: 'Пожалуйста введите телефон',
        minlength: 'Телефон введен неполностью'
      },
      phone3: {
        required: 'Пожалуйста введите телефон',
        minlength: 'Телефон введен неполностью'
      },
      pass: {
        required: 'Пожалуйста введите пароль',
          //maxlength: 'Слишком длинный'
      }
    },
    // submitHandler: function (form) {
    //   submitFormLead(form);
    // }
  });
});

$('input[type="tel"]').on("keyup blur", function () {
  let phone = $(this).val();
  if (phone.substr(0, 1) == "8" || phone.substr(0, 1) == "7") {
    $(this).val(phone.replace(phone.substr(0, 1), "+7 (")).mask("+7 (000) 000-00-00", { clearIfNotMatch: false });
  } else if (phone == "" || !phone) {
    $(this).unmask();
  } else {
    $(this).mask("+7 (000) 000-00-00", { clearIfNotMatch: false });
    $(this).val(phone);
  }
});

const pass = document.getElementById('pass'),
      passChange = document.querySelector('.change-icon-visibility');
passChange.addEventListener('click', showPass)
function showPass() {
  (pass.type === "password") ? pass.type = "text" : pass.type = "password";
}

function showLoader() {
  $('.loader').fadeIn(0);
}
function hideLoader() {
  $('.loader').fadeOut(0);
}
function validSucess() {
  $('#result-sucess').fadeIn(500);
}
function validError() {
  $('#result-error').fadeIn(500);
}
function hideResult() {
  $('.result-box').fadeOut(0);
}